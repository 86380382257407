import React, {useState, useRef, useEffect} from 'react';
import s from './VideoWidget.module.scss';
import {$api} from '../../api/api';
import {useParams} from 'react-router-dom';

function VideoPlayer() {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videoSrc, setVideoSrc] = useState(null);
    const [videoUrls, setVideoUrls] = useState(JSON.parse(localStorage.getItem('VIDEOS_URLS')) || []);
    const videoRef = useRef(null);
    const {publicId, stopId} = useParams();
    const cacheName = 'video-cache-v1';

    // Fetch video URLs from the API every 6 seconds
    useEffect(() => {
        async function fetchVideoUrls() {
            try {
                const response = await $api.get('/api/info-hub/ads/videos', {
                    params: {publicId, stopId},
                });
                console.log('new videos urls', JSON.stringify(response.data))
                setVideoUrls(response.data);
                localStorage.setItem("VIDEOS_URLS", JSON.stringify(response.data))
            } catch (e) {
                console.log('Error fetching video URLs:', e);
            }
        }

        fetchVideoUrls();

        const interval = setInterval(fetchVideoUrls, 300_000);
        return () => clearInterval(interval);
    }, [publicId, stopId]);

    // Cleanup cache when videoUrls change
    useEffect(() => {
        async function cleanupCache() {
            try {
                const cache = await caches.open(cacheName);
                const cachedRequests = await cache.keys();

                for (const request of cachedRequests) {
                    if (!videoUrls.includes(request.url)) {
                        await cache.delete(request);
                        console.log('Removed from cache:', request.url);
                    }
                }
            } catch (error) {
                console.error('Error cleaning up cache:', error);
            }
        }

        if (videoUrls?.length > 0) {
            cleanupCache();
        }
    }, [videoUrls, cacheName]);

    // Fetch and cache the current video
    useEffect(() => {
        async function fetchAndCacheVideo(url) {
            try {
                const cache = await caches.open(cacheName);
                const cachedResponse = await cache.match(url);

                if (cachedResponse) {
                    console.log('Serving from cache:', url);
                    const blob = await cachedResponse.blob();
                    setVideoSrc(URL.createObjectURL(blob));
                } else {
                    console.log('Fetching and caching:', url);
                    const response = await fetch(url, {mode: 'cors'});
                    if (response.ok) {
                        await cache.put(url, response.clone());
                        const blob = await response.blob();
                        setVideoSrc(URL.createObjectURL(blob));
                    } else {
                        console.error('Failed to fetch video:', response.statusText);
                    }
                }
            } catch (error) {
                console.error('Error fetching and caching video:', error);
                setVideoSrc(url); // Fallback to direct URL
            }
        }

        if (videoUrls.length > 0) {
            fetchAndCacheVideo(videoUrls[currentVideoIndex]);
        }

        // Clean up object URLs
        return () => {
            if (videoSrc) {
                URL.revokeObjectURL(videoSrc);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVideoIndex, videoUrls, cacheName]);

    // Handle video end event
    const handleVideoEnded = () => {
        setCurrentVideoIndex((prevIndex) => {
            if (prevIndex + 1 < videoUrls.length) {
                return prevIndex + 1;
            } else {
                return 0;
            }
        });
    };

    if (!videoSrc) {
        return (
            <div className={s.VideoWidget}>
                <div className={s.loading}>Loading video...</div>
            </div>
        );
    }

    return (
        <div className={s.VideoWidget}>
            <video
                className={s.video}
                ref={videoRef}
                src={videoSrc}
                autoPlay
                muted
                controls={false}
                onEnded={handleVideoEnded}
                style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '15px',
                    overflow: 'hidden',
                }}
            >
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default VideoPlayer;
