import React from 'react';

export class RouterBoudary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: "" };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Fallback UI with error message and a reload option
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <p>{this.state.errorMessage}</p>
                    <button onClick={() => window.location.reload()}>
                        Retry
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}
