import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from './translations.json'

const urlLang = new URLSearchParams(window.location.search).get('lang') || 'ua'
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: urlLang,
        interpolation: {escapeValue: false}
    });

export default i18n;
