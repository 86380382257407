import React, {useState, useEffect, memo, useRef} from 'react';
import s from './MarqueeRow.module.scss';
import Marquee from "react-fast-marquee";
import {ReactComponent as LinesIcon} from '../../assets/icons/45degreeLines.svg'
import {$api} from "../../api/api";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {cls} from "../../utils/helpers/classNames";

const AlertMessage = ({alert}) => {
    const {t} = useTranslation()
    return (<div className={s.alertMessage}>
        <LinesIcon />
        <span>{t(alert)}!</span>
    </div>)
}

const ShelterMessage = memo(() => {
    const [isInEnglish, setIsInEnglish] = useState(false)
    const [shelters, setShelters] = useState([])
    const [shelterName, setShelterName] = useState('')
    const [shelterTitle, setShelterTitle] = useState('')
    const shelterCountRef = useRef(0)
    const cycleCountRef = useRef(0)
    const {publicId, stopId} = useParams();

    useEffect(() => {
        fetchShelters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shelters.length) {
            setShelterName(shelters[0].address)
            setShelterTitle('Найближчі укриття:')
            const interval = setInterval(() => {
                if (shelterCountRef.current < shelters.length - 1) {
                    shelterCountRef.current += 1;
                } else {
                    shelterCountRef.current = 0;
                }

                setShelterName(shelters[shelterCountRef.current][isInEnglish ? 'addressEn' : 'address']);
                setShelterTitle(isInEnglish ? 'Nearby shelters:': 'Найближчі укриття:')
                cycleCountRef.current += 1;
                if (cycleCountRef.current >= 8) {
                    setIsInEnglish((prev) => !prev);
                    cycleCountRef.current = 0;
                }
            }, 10_000);

            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shelters, isInEnglish])

    async function fetchShelters() {
        try {
            const response = await $api.get('/api/info-hub/shelters/nearby', {
                params: {publicId, stopId}
            })
            setShelters(response.data)
        } catch (e) {
            console.log('error')
        }
    }

    return (
        <div className={s.shelterInfo}>
        <span className={cls('', {[s.shelterTitle]: shelters.length})}>{shelterTitle}</span>
        <span className={cls('', {[s.shelterMessage]: shelters.length})}>{shelterName}</span>
    </div>
    )
})

const MarqueeRow = () => {
    const [activeAlerts, setActiveAlerts] = useState([])
    const {publicId, stopId} = useParams();

    useEffect(() => {
        fetchAlerts()
        const interval = setInterval(() => {
            fetchAlerts()
        }, 10_000)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchAlerts() {
        try {
            const response = await $api.get('/api/info-hub/alarms', {
                params: {publicId, stopId}
            })
            const activeAlertsArr = response.data.activeAlerts
            if (activeAlertsArr?.length) {
                if (activeAlertsArr?.length > 1) {
                    setActiveAlerts(activeAlertsArr)
                } else {
                    setActiveAlerts([activeAlertsArr[0], activeAlertsArr[0]])
                }
            } else {
                setActiveAlerts([])
            }
        } catch (e) {
            console.log('error')
        }
    }

    return (
        <>
            <div className={cls('', {[s.hidden]: !activeAlerts.length})}>
                <Marquee style={{backgroundColor: '#FF735C'}}>
                    {activeAlerts?.map(alert => <AlertMessage alert={alert} />)}
                </Marquee>
            </div>
            <div className={cls('', {[s.hidden]: activeAlerts.length})}>
                <ShelterMessage />
            </div>
        </>
    );
}

export default MarqueeRow;
