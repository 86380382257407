import React, {useEffect, useState} from "react";
import {$api} from "../../api/api";
import {useParams} from "react-router-dom";
import {ReactComponent as NoInternetIcon} from "../../assets/icons/no-internet.svg";
import {ReactComponent as ServerErrorIcon} from "../../assets/icons/server-error.svg";
import VideoWidget from "../../components/VideoWidget/VideoWidget";
import Table from "../../components/Table/Table";
import CommonInfoWidget from "../../components/CommonInfoWidget/CommonInfoWidget";
import {
    ERROR_COUNT,
    internetError,
    REACT_APP_VERSION,
    serverError, STOP_NAME,
    SUCCESS_REQUEST_AMOUNT
} from "../../utils/consts/common";
import {useTranslation} from "react-i18next";
import s from './App.module.scss'
import {cls} from "../../utils/helpers/classNames";
import useVersionCheck, {fetchVersion} from "../../utils/hooks/useCheckVersion";
import MarqueeRow from "../../components/Marquee/MarqueeRow";

function App() {
    useVersionCheck()
    const storedVersion = localStorage.getItem(REACT_APP_VERSION);
    const {t} = useTranslation()
    const [requestError, setRequestError] = useState(null)
    const [stopData, setStopData] = useState(null)
    const {publicId, stopId, SBoardId} = useParams();

    useEffect(() => {
        (async function () {
            const currentVersion = await fetchVersion();
            localStorage.setItem(REACT_APP_VERSION, currentVersion)
        }())
    }, [])

    useEffect(() => {
        if (requestError?.type === 'internetError') {
            const divElements = document.getElementsByClassName('weather-widget');
            if (divElements[0]) {
                divElements[0].style.opacity = '0';
            }
        } else {
            const divElements = document.getElementsByClassName('weather-widget');
            if (divElements[0]) {
                divElements[0].style.opacity = '1';
            }
        }
    }, [requestError]);

    useEffect(() => {
        fetchTransports()
        localStorage.setItem(ERROR_COUNT, '0')
        localStorage.setItem(SUCCESS_REQUEST_AMOUNT, '0')
        const interval = setInterval(() => {
            fetchTransports()
        }, 10_000)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchTransports() {
        const successRequestAmount = Number(localStorage.getItem(SUCCESS_REQUEST_AMOUNT))
        try {
            const response = await $api.get('/api/dispatching/public/locator/stop/schedule', {
                params: {
                    publicId: publicId,
                    stopId: stopId
                }
            })
            localStorage.setItem(ERROR_COUNT, '0')
            localStorage.setItem(SUCCESS_REQUEST_AMOUNT, String(successRequestAmount + 1))
            setRequestError(null)
            setStopData(response.data)
            localStorage.setItem(STOP_NAME, response.data.stopName)
        } catch (e) {
            let errorCount = Number(localStorage.getItem(ERROR_COUNT))
            if (errorCount >= 7 || successRequestAmount === 0) {
                if (e.request.status === 0) {
                    setRequestError(internetError)
                } else {
                    setRequestError(serverError)
                }
            }
            localStorage.setItem(ERROR_COUNT, String(errorCount + 1))
        }
    }

    const errorComponent = (
        <div className={s.errorMessage}>
            {requestError?.type === 'internetError' ? <NoInternetIcon /> : <ServerErrorIcon />}
            <div className={s.title}>{t(requestError?.title)}</div>
            {requestError?.subtitles.map(subtitle => (
                <div
                    className={cls(s.subtitle, {[s.noInternetSubtitle]: requestError.type === 'internetError'})}>{t(subtitle)}</div>
            ))}
            <div className={s.subtitle}>ID: {SBoardId} &nbsp; Version: {storedVersion}</div>
        </div>
    )

    return (
        <div>
            <div className={s.app}>
                <div className={s.widgets}>
                    <CommonInfoWidget stopName={stopData?.stopName} />
                    <VideoWidget />
                </div>
                <MarqueeRow text={'Повітряна тривога!'} />
                {requestError
                    ? errorComponent
                    : (
                        <Table
                        requestError={requestError}
                        stopData={stopData}
                    />
                    )
                }
            </div>
            <div className={s.beta}>
                <div className={s.title}>BETA</div>
                <span>Табло працює в режимі дослідної експлуатації <span className={s.version}>({storedVersion})</span></span>
                <span className={s.site}>www.intranso.com</span>
            </div>
        </div>
    )
}

export default App
