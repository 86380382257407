// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonInfoWidget_CommonInfoWidget__9RwiJ {
  width: 294px;
  height: 426px;
  border-radius: 30px;
  background-color: #02276B;
  display: grid;
  grid-template-rows: 200px 68px 35px;
  padding: 98px 25px 36px;
  box-sizing: border-box;
}

.CommonInfoWidget_stopName__qdoZS {
  font: var(--font-32-500);
}

.CommonInfoWidget_HHmm__tTLpq {
  font: var(--font-62);
}

.CommonInfoWidget_date__QJz4g {
  font-size: 20px;
}

.CommonInfoWidget_colon__r\\+gG0 {
  animation: CommonInfoWidget_fade__md2Hc 2s infinite;
}

@keyframes CommonInfoWidget_fade__md2Hc {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CommonInfoWidget/CommonInfoWidget.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mCAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,mDAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".CommonInfoWidget {\n  width: 294px;\n  height: 426px;\n  border-radius: 30px;\n  background-color: #02276B;\n  display: grid;\n  grid-template-rows: 200px 68px 35px;\n  padding: 98px 25px 36px;\n  box-sizing: border-box;\n}\n\n.stopName {\n  font: var(--font-32-500);\n}\n\n.HHmm {\n  font: var(--font-62);\n}\n\n.date {\n  font-size: 20px;\n}\n\n.colon {\n  animation: fade 2s infinite;\n}\n\n@keyframes fade {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommonInfoWidget": `CommonInfoWidget_CommonInfoWidget__9RwiJ`,
	"stopName": `CommonInfoWidget_stopName__qdoZS`,
	"HHmm": `CommonInfoWidget_HHmm__tTLpq`,
	"date": `CommonInfoWidget_date__QJz4g`,
	"colon": `CommonInfoWidget_colon__r+gG0`,
	"fade": `CommonInfoWidget_fade__md2Hc`
};
export default ___CSS_LOADER_EXPORT___;
