import {ReactComponent as Icon0} from "../../assets/icons/0.svg";
import {ReactComponent as Icon25} from "../../assets/icons/25.svg";
import {ReactComponent as Icon50} from "../../assets/icons/50.svg";
import {ReactComponent as Icon75} from "../../assets/icons/75.svg";
import {ReactComponent as Icon100} from "../../assets/icons/100.svg";

function roundLoadPercentage(loadPercentage) {
    const steps = [0, 25, 50, 75, 100];

    return steps.reduce((prev, curr) => {
        return (Math.abs(curr - loadPercentage) < Math.abs(prev - loadPercentage) ? curr : prev);
    });
}

export function TrafficChart({loadData}) {
    if (!loadData) {
        return '-'
    }

    const ration = roundLoadPercentage(loadData.loadPercentage)
    switch (ration) {
        case 0:
            return <Icon0 />
        case 25:
            return <Icon25 />
        case 50:
            return <Icon50 />
        case 75:
            return <Icon75 />
        case 100:
            return <Icon100 />
        default:
            return <Icon0 />
    }
}
