import { useEffect } from 'react';
import {REACT_APP_VERSION} from "../consts/common";

export const fetchVersion = async () => {
    try {
        const response = await fetch('/version.json'); // Fetch the file from the public directory
        const data = await response.json();
        return data.version;
    } catch (error) {
        console.error('Error fetching version:', error);
        return null;
    }
};


const useVersionCheck = (interval = 180000) => {
    const checkVersion = async () => {
        const currentVersion = await fetchVersion();
        const storedVersion = localStorage.getItem(REACT_APP_VERSION);
        console.log('currentVersion: ', currentVersion, ' | storedVersion: ', storedVersion )

        if (storedVersion !== currentVersion) {
            localStorage.setItem(REACT_APP_VERSION, currentVersion);
            window.location.reload(true); // Force reload to load the new version
        }
    };

    useEffect(() => {
        const versionCheckInterval = setInterval(() => {
            checkVersion();
        }, interval); // Run the check periodically (e.g., every minute)

        return () => clearInterval(versionCheckInterval); // Cleanup interval on unmount
    }, [interval]);
};

export default useVersionCheck;
