import {useEffect} from "react";
import {NavLink, useParams} from "react-router-dom";

function NotFound() {
    const params = useParams()['*'].split('/')
    useEffect(() => {
        const divElements = document.getElementsByClassName('weather-widget');
        if (divElements?.[0]) {
            divElements[0].style.display = 'none';
        }
    }, []);

    const publicId = !!params[1]
        ? <span>✅ <strong>publicId</strong> вказаний</span>
        : <span>❌ <strong>publicId</strong> не вказаний</span>
    const stopId = !!params[2]
        ? <span>✅ <strong>stopId</strong> вказаний</span>
        : <span>❌ <strong>stopId</strong> не вказаний</span>
    const weatherCityId = !!params[3]
        ? <span>✅ <strong>weatherCityId</strong> вказаний</span>
        : <span>❌ <strong>weatherCityId</strong> не вказаний</span>
    const SBoardId = !!params[4]
        ? <span>✅ <strong>SBoardId</strong> вказаний</span>
        : <span>❌ <strong>SBoardId</strong> не вказаний</span>

    return (
        <div style={{
            display: 'flex',
            alignItems: "center",
            marginTop: 350,
            gap: 20,
            flexDirection: 'column',
            height: '90vh',
            maxWidth: '100%',
            fontSize: '34px',
            fontWeight: 400
        }}>
            <div>
                <h4>Потрібно здійснити налаштування</h4>
                <details>
                    <summary>
                        Деталі:
                    </summary>
                    <ol>
                        <li style={{marginBottom: 40}}>{publicId}</li>
                        <li style={{marginBottom: 40}}>{stopId}</li>
                        <li style={{marginBottom: 40}}>{weatherCityId}</li>
                        <li style={{marginBottom: 40}}>{SBoardId}</li>
                    </ol>
                    <br /><br />
                    <div style={{fontSize: 18, lineHeight: '30px'}}>
                        <strong>Приклад коректного посилання: </strong>
                        <br />
                        {window.location.host}/sboard/[**publicId**]/[**stopId**]/[**weatherCityId**]/[**sBoardId**]
                    </div>
                    <div>
                        <NavLink to={'/config'} style={{textDecoration: 'none'}}>
                            <button style={{
                                cursor: 'pointer',
                                fontSize: 30,
                                lineHeight: '38px',
                                padding: 12,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '30px',
                                backgroundColor: "#000",
                                color: '#fff',
                                borderRadius: '15px',
                                border: '2px solid #fff',
                                textDecoration: 'none'
                            }}>Створити посилання
                            </button>
                        </NavLink>
                    </div>
                </details>

            </div>
        </div>
    );
}

export default NotFound;
