export const internetError = {
    type: 'internetError',
    title: "Немає звязку",
    subtitles: [
        "Наші фахівці вже працюють над вирішенням проблеми.",
        "Вибачте за тимчасові незручності."
    ]
}

export const serverError = {
    type: 'serverError',
    title: "Не вдалось підключитись до серверу",
    subtitles: [
        "Ми вже працюємо над вирішенням проблеми.",
        "Вибачте за тимчасові незручності."
    ]
}

export const ERROR_COUNT = 'ERROR_COUNT'
export const SUCCESS_REQUEST_AMOUNT = 'SUCCESS_REQUEST_AMOUNT'
export const REACT_APP_VERSION = 'REACT_APP_VERSION'
export const STOP_NAME = 'STOP_NAME'
export const weatherHeaders = {
    'Accept': '*/*',
    'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8,uk;q=0.7',
    'Content-Type': 'application/x-www-form-urlencoded',
}
