import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';
import './services/i18next/i18n'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {RouterBoudary} from "./components/ErrorBoundary/RouterBoudary";
import NotFound from "./pages/NotFound/NotFound";
import ConfigLink from "./pages/ConfigLink/ConfigLink";

const router = createBrowserRouter([
    {
        path: "/sboard/:publicId/:stopId/:weatherCityId/:SBoardId",
        element: <App />,
    },{
        path: "/config",
        element: <ConfigLink />,
    }, {
        path: "*",
        element: <RouterBoudary><NotFound /></RouterBoudary>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterBoudary>
            <RouterProvider router={router} />
        </RouterBoudary>
    </React.StrictMode>
);

reportWebVitals();
