// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/Inter-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}`, "",{"version":3,"sources":["webpack://./src/styles/_fonts.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA0C;AAC5C;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA4C;AAC9C;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA6C;AAC/C;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA2C;AAC7C","sourcesContent":["@font-face {\n  font-family: \"Inter\";\n  font-weight: 600;\n  src: url(\"../assets/fonts/Inter-Bold.ttf\");\n}\n\n@font-face {\n  font-family: \"Inter\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/Inter-Medium.ttf\");\n}\n\n@font-face {\n  font-family: \"Inter\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/Inter-Regular.ttf\");\n}\n\n@font-face {\n  font-family: \"Inter\";\n  font-weight: 300;\n  src: url(\"../assets/fonts/Inter-Light.ttf\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
