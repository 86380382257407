import {memo, useEffect, useState} from 'react'
import s from './CommonInfoWidget.module.scss'
import moment from 'moment';
import 'moment/locale/uk';
import {STOP_NAME} from "../../utils/consts/common"; // імпорт локалі української мови

moment.locale('uk'); // встановлення локалі на українську

const CommonInfoWidget = memo(({stopName}) => {
        const storageStopName = localStorage.getItem(STOP_NAME)
        const [HHmm, setHHmm] = useState(moment().format("HH:mm"))
        const [date, setDate] = useState(moment().format('dddd, D MMMM'))
        useEffect(() => {
            const timeInterval = setInterval(() => {
                setHHmm(moment().format("HH:mm"))
                setDate(moment().format('dddd, D MMMM'))
            }, 1000)
            return () => {
                clearInterval(timeInterval)
            }
        }, [])

        return (
            <div className={s.CommonInfoWidget}>
                <div className={s.stopName}>{stopName || storageStopName}</div>
                <div className={s.HHmm}>
                    <span>{HHmm.slice(0,2)}</span>
                    <span className={s.colon}>:</span>
                    <span>{HHmm.slice(3,5)}</span>
                </div>
                <span className={s.date}>{date}</span>
            </div>
        )
    }
)
export default CommonInfoWidget
