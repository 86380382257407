// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoWidget_VideoWidget__5FeBz {
  width: 756px;
  height: 426px;
  border-radius: 30px;
  background: #232323;
}

.VideoWidget_video__eIv7p {
  border-radius: 30px;
  width: 756px;
  height: 426px;
}

.VideoWidget_loading__LbNEi {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/VideoWidget/VideoWidget.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".VideoWidget {\n  width: 756px;\n  height: 426px;\n  border-radius: 30px;\n  background: #232323;\n}\n\n.video {\n  border-radius: 30px;\n  width: 756px;\n  height: 426px;\n}\n\n.loading {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VideoWidget": `VideoWidget_VideoWidget__5FeBz`,
	"video": `VideoWidget_video__eIv7p`,
	"loading": `VideoWidget_loading__LbNEi`
};
export default ___CSS_LOADER_EXPORT___;
